import React, {useContext} from "react";
import "./SplashScreen.css";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import {greeting, splashScreen} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import logo2 from "../../assets/lottie/logo.png";

export default function SplashScreen() {
  const {isDark} = useContext(StyleContext);
  return (
    <div className={isDark ? "dark-mode splash-container" : "splash-container"}>
      <div className="splash-animation-container">
      <img src={logo2} alt="Logo" className="logo-image" style={{ width: '200px', height: 'auto' }} />
        {/* <DisplayLottie animationData={logo2} /> */}
      </div>
      <div className="splash-title-container">
        
        <span className="splash-title">We Love Trump</span>
        
      </div>
    </div>
  );
}
