/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Mavro Coins",
  title: "We Love Trump",
  subTitle: emoji(
    "We Love Trump not for his politics, but for his personal qualities—his grit, charm, and passion for his dreams. We Love Trump because he is Trump."
  ),
  resumeLink:
    "https://drive.google.com", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://x.com/welovetrump_xyz",
  linkedin: "https://t.me/+opg3UwsfeDI4NTU0",
  gmail: "admin@wltcoin.com",
  gitlab: "https://gitlab.com/saadpasta",
  facebook: "https://www.facebook.com/mavrocoin",
  medium: "https://medium.com/@mavrocoin",
  stackoverflow: "https://stackoverflow.com/users/mavrocoin",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "",
  subTitle: "",
  skills: [
   
    
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection2 = {
  title: "",
  subTitle: "",
  skills: [
   
    
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection3 = {
  title: "Transparent",
  subTitle: "",
  skills: [
   
    
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection4 = {
  title: "Join the WLT",
  subTitle: "Welcome to WE LOVE TRUMP - the cryptocurrency that embodies the spirit of patriotism, innovation, and unwavering support for the 45th President of the United States.",
  skills: [
   
    emoji(
      "⚡ Patriotic Investment: Be part of a community that values freedom, innovation, and economic prosperity."
    ),
    emoji(
      "⚡ Innovative Technology: Benefit from the decentralized nature of cryptocurrency, protecting your assets from traditional banking constraints."
    ),
    emoji(
      "⚡Community Driven: Join a passionate and like-minded community of Trump supporters who believe in the values of honesty, integrity, and American greatness."
    ),
    emoji(
      "⚡Exclusive Rewards: Holders of WE LOVE TRUMP will gain access to exclusive content, merchandise, and events related to President Trump."
    ),
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection5 = {
  title: "The Vision Forward",
  subTitle: "",
  skills: [
   
    
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Binace Feed",
      logo: require("./assets/images/binance.jpg"),
      subHeader: "",
      duration: "",
      desc: "",
      descBullets: [
        
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "",
      company: "MEXC",
      companylogo: require("./assets/images/mexc.png"),
      date: "",
      desc: "",
      descBullets: [
      ]
    },
    {
      role: "",
      company: "GATE",
      companylogo: require("./assets/images/gate.png"),
      date: "",
      desc: ""
    },
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Partners",
  subtitle: "More Coming Soon",
  projects: [
    {
      image: require("./assets/images/mexc.png"),
      projectName: "Bitmart",
      projectDesc: "",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://bitmart.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    // {
    //   image: require("./assets/images/gate.png"),
    //   projectName: "GATE",
    //   projectDesc: "",
    //   footerLink: [
    //     {
    //       name: "Visit Website",
    //       url: "http://gate.io/"
    //     }
    //   ]
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Tokenomics "),
  subtitle:
    "Token Distribution",

  achievementsCards: [
    // {
    //   title: "",
    //   subtitle:
    //     "",
    //   image: require("./assets/images/tokenomicsa.png"),
    //   imageAlt: "",
    //   footerLink: [
       
    //   ]
    // },
    {
      title: "",
      subtitle:
        "",
      image: require("./assets/images/tokenomicsb.png"),
      imageAlt: "",
      footerLink: [
       
      ]
    },
   
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Roadmap",
  subtitle:
    "TBD (maybe next month)",
  displayMediumBlogs: "false", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    // {
    //   url: "",
    //   title: "Foundation and Launch(Completed)",
    //   description:
    //     "Forge strategic partnerships with  stakeholders to expand the project's reach and utility."
    // },
    // {
    //   url: "",
    //   title: "Initial Development and Listing",
    //   description:
    //     "Conduct thorough security audits of the WLT  smart contract by reputable third-party firms.List WLT  Coin on major decentralized exchanges (DEXs) such as PancakeSwap. Introduce a staking platform where users can stake WLT  tokens and earn rewards."
    // },
    // {
    //   url: "",
    //   title: "Expansion and Integration",
    //   description:
    //     "Begin development of decentralized applications (DApps) that enhance the utility of WLT  tokens."
    // },
    // {
    //   url: "",
    //   title: "Advanced Features and Adoption",
    //   description:
    //     "Establish a governance mechanism to enable community-driven decision-making and ensure the project's long-term sustainability and decentralization."
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact US"),
  subtitle:
    "We have a dedicated support team",
  number: "",
  email_address: "admin@WLTcoins.com"
};

// Twitter Section

const twitterDetails = {
  userName: "welovetrump_xyz", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  skillsSection2,
  skillsSection3,
  skillsSection4,
  skillsSection5,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
