import React, { useContext } from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import logo2 from "../../assets/lottie/logo.png";
import {
  workExperiences,
  skillsSection,
  openSource,
  blogSection,
  talkSection,
  achievementSection
} from "../../portfolio";

function Header() {
  const { isDark } = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
        <img src={logo2} alt="Logo" className="logo-image" style={{ width: '50px', height: 'auto' }} />

        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#greeting">About</a>
            </li>
          )}
          
          {viewOpenSource && (
            <li>
              <a href="#listing">CEX Listing</a>
            </li>
          )}
          {viewAchievement && (
            <li>
              <a href="#tokenomics">Tokenomics</a>
            </li>
          )}
          {viewBlog && (
            <li>
              <a href="#road">Roadmap</a>
            </li>
          )}
          <li>
            <a href="#contact">Contact Us</a>
          </li>
          <li>
            <a href="buy.html">Pre-sell</a>
          </li>
          <li>
            <ToggleSwitch />
          </li>
        </ul>
      </header>
    </Headroom>
  );
}

export default Header;
