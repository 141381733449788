import React, { useContext } from "react";
import "./Skills.scss";
import { illustration, skillsSection4 } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import videoSource from "../../assets/lottie/1.mp4";
import logo2 from "../../assets/lottie/logo.png";

export default function Skills3() {
  const { isDark } = useContext(StyleContext);

  if (!skillsSection4.display) {
    return null;
  }

  return (
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
          <img src={logo2} alt="Logo" />
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1 className={isDark ? "dark-mode skills-heading" : "skills-heading"}>
              {skillsSection4.title}
            </h1>
            <p className={isDark ? "dark-mode subTitle skills-text-subtitle" : "subTitle skills-text-subtitle"}>
              {skillsSection4.subTitle}
            </p>
            <div>
              {skillsSection4.skills.map((skill, i) => (
                <p key={i} className={isDark ? "dark-mode subTitle skills-text" : "subTitle skills-text"}>
                  {skill}
                </p>
              ))}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
